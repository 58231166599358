<template>
	<div class="exhibitors">
        <div v-if="loading">
            <svg width="1em" height="1em" viewBox="0 0 20 20" focusable="false" role="img" alt="icon" xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi-spin ml-1 bi-arrow-clockwise bi-spin ml-1 b-icon bi" style="font-size: 300%;"><g><path fill-rule="evenodd" d="M10 4.5a5.5 5.5 0 105.5 5.5.5.5 0 011 0 6.5 6.5 0 11-3.25-5.63l-.5.865A5.472 5.472 0 0010 4.5z" clip-rule="evenodd"></path><path fill-rule="evenodd" d="M10.646 1.646a.5.5 0 01.708 0l2.5 2.5a.5.5 0 010 .708l-2.5 2.5a.5.5 0 01-.708-.708L12.793 4.5l-2.147-2.146a.5.5 0 010-.708z" clip-rule="evenodd"></path></g></svg>
        </div>
        <div v-else-if="error" style="color:red; padding-bottom:30px;">{{ error }}<br><br>Bitte laden Sie diese Seite zu einem späteren Zeitpunkt noch einmal.</div>
        <div v-else-if="exhibitors && exhibitors.published" class="exhibitors">

            <pre v-if="0">{{ exhibitorsSorted }}</pre>
            
            <div v-for="(exb, index) in exhibitorsFiltered" :key="'exb-'+index" :class="'exhibitor '+(false && exb.packageId > 0 ? 'premium':'standard')">
                <pre v-if="0">{{ exb }}</pre>
                
                <div class="text">
                    <div class="title">{{ exb.title }}</div>
                    <div class="premium" v-if="false && exb.packageId > 0">
                        Premium-Sponsor
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
                            <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
                        </svg>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
                            <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
                        </svg>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
                            <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
                        </svg>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
                            <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
                        </svg>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
                            <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
                        </svg>
                    </div>
                    
                    <div v-if="exb.logo" class="logo" :style="'background-image: url('+exb.logo.src+')'" :title="'Logo des Ausstellers '+(exb.title)+''"></div>
                    {{ exb.description }}

                    <div style="margin-top:20px;">
                        Weitere Informationen finden Sie auf der <a :href="exb.websiteUrl" v-if="exb.websiteUrl" target="_blank" :title="'Hier gelangen Sie zur Website von '+(exb.title)+''">Website des Ausstellers</a>
                    </div>
                </div>
                
            </div>

        </div>
        <div v-else class="alert alert-sm alert-primary">Die teilnehmenden Aussteller sind derzeit noch in Planung. Die Liste wird zu einem späteren Zeitpunkt an dieser Stelle veröffentlicht.</div>
	</div>
</template>

<script>


import axios from 'axios';

export default {
	name: 'webComponent',
	components:{},
    props:{
        file: {
            type: String,
            default: "https://duesseldorfer-diabetestag.de/wp-content/plugins/ddt-admin/api/getExhibitors"
        }
    },
	data: function () {
        
		return {
            axios,
            url:this.file,
            loading:true,
            testProgressAnimation:0, // ms | false | 0
            error:false,
			exhibitors:false,
			roomIndex:0
		}
	},
    async created(){
        this.exhibitors = await this.load();
        this.loading = false;
    },
    methods:{
        async load(){
            
            var response;
            try {
                response = await axios.post(this.url);
                
            } catch (error) {
                response = false;
                console.log(error);
            }

            console.log("response", response);
            return new Promise((resolve, reject)=>{
                if( ! response || typeof response.data == 'undefined' )
                {
                    this.error = "Die Datendatei enthält kein gültiges JSON-Format.";
                    reject();
                }

                (async () => {
                    if( this.testProgressAnimation )
                        await new Promise((res) => setTimeout(res, this.testProgressAnimation));
                    resolve(response.data);
                })();
            
                return true;
            });
        },
    },

    computed:{
        
        // exhibitorsSorted(){

        //     if(! this.exhibitors)
        //         return [];

        //     var premium = this.exhibitors.items.filter((exb) => {
        //         return exb.packageId > 0;
        //     }).sort((a, b) => {
        //         if (a.title < b.title) return -1;
        //         if (a.title > b.title) return 1;
        //         return 0;
        //     });

        //     var standard = this.exhibitors.items.filter((exb) => {
        //         return exb.packageId == 0;
        //     }).sort((a, b) => {
        //         if (a.title < b.title) return -1;
        //         if (a.title > b.title) return 1;
        //         return 0;
        //     });

        //     return premium.concat(standard);
        // },

        exhibitorsFiltered() {
            if(! this.exhibitors )
                return [];

            const items = this.exhibitors.items;

            const filtered = [];
            for (let i = 0; i < items.length; i++){
                var item = items[i];
                if( item.active )
                    filtered.push(item);    
            }
            return filtered;
        },
    }
}
</script>

<style type="text/scss">

.exhibitors {
    /* margin-left:auto;
    margin-right:auto;
    max-width: 1000px; */
    display:flex;
    flex-wrap:wrap;
    justify-content:space-between;
    gap:20px;

    display: inline-flex;
    width:100%;
}

.exhibitors .alert{
    display:inline-block;
    padding:30px;
}

.exhibitors .alert-primary{
    background-color: #eaf4f4;
    border-color: #cddddd;
    color:black;
}

.exhibitor {
    width:100%;
    display:flex;
    justify-content:flex-start;
    gap:20px;
    margin-bottom:2em;
    padding-right: 1px;
    
    border:10px solid rgb(241, 241, 241);
    padding:20px;
}

.exhibitor.premium{
    border:14px solid rgb(215, 191, 52);
    background-color: rgba(215, 191, 52, 0.046);

    box-shadow: 10px 10px 25px -18px rgba(0,0,0,0.2);
}





.exhibitor .logo{
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    background-color: white;
    width:180px;
    
    height:120px;
    border:1px solid #AAA;
    float:right;
    margin-left:2em;
    margin-bottom:2em;
}
.exhibitor.premium .logo{
    width:240px;
    height:160px;
}




@media (min-width: 800px) {
	
    /* .exhibitor:not(.premium){
        width: calc(50% - 20px);
        
        
    } */
}

@media (max-width: 799px) {
	
    .exhibitor .logo{
        width:100% !important;
        height:140px !important;
        float:none;
        margin-left:0;
        margin-bottom:1em;
    }
}

.exhibitor .text{
    width:100%;
    line-height:1.4em;
}
.exhibitor .title{
    font-size:1.2em;
    font-weight:bold;
    padding-bottom:0.2em;
    margin-bottom: 0.3em;
}
.exhibitor.premium .title{
    font-size:1.4em;
}
.exhibitor .premium{
    font-size:1.2em;
    font-weight:bold;
    color:rgb(215, 191, 52);
    margin-bottom: 0.3em;
}
.exhibitor .descr{
    
}




/* .item-container {
	padding:15px;
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	gap:20px;
}

.item-container .item {
	width: 100%;
    height:auto;
	color:inherit;
}

.item-container .item.package-2{
    height:300px;
}

@media (min-width: 600px) {
	
	.item-container .item{
		width: calc((100% - (1 * 20px)) / 2);
	} 
}

@media (min-width: 800px) {
	.item-container {
		padding:40px;
	}
	.item-container .item{
		width: calc((100% - (2 * 20px)) / 3);
	}
}


@media (min-width: 1100px) {
	.item-container .item{
		width: calc((100% - (3 * 20px)) / 4);
	}
}

@media (min-width: 1400px) {
	.item-container .item{
		width: calc((100% - (4 * 20px)) / 5);
	}
}

@media (min-width: 1700px) {
	.item-container .item{
		width: calc((100% - (5 * 20px)) / 6);
	}
}

.item-container {
	padding-top:40px;
	padding-bottom:0px;
} */


</style>